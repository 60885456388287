import React from 'react';
import { PageProps } from 'gatsby';
import Wrapper from '@/layouts/wrapper';
import Seo from '@/components/Seo';
import Container from '@/components/Container';
import GradientText from '@/components/GradientText';
import Link from '@/components/Link';
import { Routes } from '@/enums/routes';

const ErrorPage: React.FC<PageProps> = () => (
  <Wrapper>
    <Seo title="Page not found" />
    <div className="min-h-[80vh] flex flex-col justify-center">
      <Container className="text-white" gutter>
        <GradientText className="text-fluid-3xl">404</GradientText>
        <h1 className="my-0">Page not found</h1>
        <div className="my-20 text-xl">
          The page you’re looking for was not found.{' '}
          <Link
            to={Routes.Home}
            className="transition-colors text-primary-500 hover:text-primary-400"
          >
            Go to home
          </Link>
        </div>
      </Container>
    </div>
  </Wrapper>
);

export default ErrorPage;
